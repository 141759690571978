<template>
    <div>
        <v-row>
            <v-col class="my-auto text-center">
                <p class="text-h5 ma-12">
                    {{ $t('app.title') }}
                </p>
                <v-btn depressed color="cyan lighten-3" x-large to="/pricing">
                    See Our Plans
                </v-btn>
            </v-col>
            <v-col>
                <img class="img-boy" src="../assets/homepage/imagen5.jpg">
            </v-col>
        </v-row>
        
        <div class="centerText my-12">
            <h2 class="text-h5">
                {{ $t('app.subTitle') }}
            </h2>
        </div>
        <v-container>
            <v-row>
                <v-col>
                    <img class="w-90" src="../assets/homepage/teacher.jpg">
                </v-col>
                <v-col class="my-lg-auto">
                    <h3 class="text-h5 py-4">{{ $t('app.teacherTitle') }}</h3>
                    <p class="subtitle-1">{{ $t('app.teacher') }}</p>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="my-lg-auto">
                    <h3 class="text-h5 py-4">{{ $t('app.studentTitle') }}</h3>
                    <p class="subtitle-1">{{ $t('app.student') }}</p>
                </v-col>
                <v-col>
                    <img class="w-100" src="../assets/homepage/student.jpg">
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    mounted(){
        location.hash = "#top";
    }
}
</script>

<style>
    .img-boy{
        width: 100%;
    }
    .centerText{
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .w-90{
        width: 90%;
    }
    .w-100{
        width: 100%;
    }
</style>